<template>
    <div class="result-card-wrap">
        <!-- 有测评的分 企业和个人的"面试者总体表现" 显示位置不同    没有测评则都显示在下面 1hr 2工人-->
        <div class="interView-desc-box" v-if="detail.interviewResult && identity == 2 ">
            <div class="desc-box--title">
                <div class="desc-box--divide"></div>
                面试者总体表现
            </div>
            <span class="desc-box--content">
                <span class="score">{{ detail.interviewAppraise + '分'}}</span>
                <!-- <span>{{ detail.interviewResult }}</span> -->
                <div>
                  <div class="interviewResult" v-for="(item,index) in detail.interviewResult" :key="index">{{ item}} {{item ? '。' : ''}} </div>
                </div>
            </span>
        </div>
        <!-- 测评结果 异常 -->
        <errorTip ref="errorTip" v-if="detail && !detail.evalResult"/>
        <div class="test-wrap">
            <!-- 测评结果 -->
            <!-- MBTI测试结果报告 -->
            <MBTITest v-if="examType == 1 && detail.evalResult" ref="MBTITest"/>
            <!-- 你的9型人格分析 -->
            <type9PersonalityTest style="padding: 20px;" v-if="examType == 2 && detail.evalResult" ref="type9PersonalityTest"/>
            <!-- 卡特尔16人格测试结果报告 -->
            <cartContent ref="carteTest" v-if="examType == 3 && detail.evalResult" />
            <slot></slot>
        </div>
    </div>
</template>

<script>
import cartContent from '@/components/myTest/carte-content.vue'
import type9PersonalityTest from '@/components/myTest/type9PersonalityTest.vue'
import MBTITest from '@/components/myTest/MBTITest.vue'
import errorTip from './errorTip.vue'
export default {
    components: {
        MBTITest,
        type9PersonalityTest,
        cartContent,
        errorTip
    },
    data() {
        return {
            detail: {},
            examType: null,
            identity: null,
        };
    },
    mounted() {
        this.examType = this.$route.query.examType
    },
    methods: {
        initInfo(detail, identity) {
            if (detail.interviewResult && typeof detail.interviewResult === 'string') {  // 面试评分
              detail.interviewResult = detail.interviewResult.split('。')
            }
            this.detail = detail
            this.identity = identity
            if (detail.evalResult && detail.evalResult.result) {
                console.log("detail.evalResult",JSON.parse(JSON.stringify(detail.evalResult)))
                this.getData(detail.evalResult.result)
            }
            this.$nextTick(() => {
                let ele = document.querySelector('.result-card-wrap')
                let ele2 = detail.evalResult ? (this.examType == 1 ? document.querySelector('.MBTITest-container') : this.examType == 2 ? document.querySelector('.type9Personality-container'): document.querySelector('.carte-content-container')) : null
                if (ele2) {
                    ele2.style.marginTop = '20px'
                }
                if (ele) {
                    ele.style.width = document.querySelector('.video-card-wrap').offsetWidth + 'px'
                }

           })

        },
        // 测评结果 分析
        getData(evalResult) {
            switch (this.examType) {
                case '1':
                    if (evalResult.characterTrait && evalResult.characterTrait.indexOf('&') != -1) {
                        var current = evalResult.characterTrait.split('&')
                        evalResult.characterTrait = current[1]
                        evalResult.characterTraitTagList = current[0].indexOf('、') != -1 ? current[0].split('、') : []
                    }
                    this.$nextTick(() => {
                        this.$refs.MBTITest.initInfo(evalResult)
                    })
                    break
                case '2':
                    var willInterestWork = evalResult.willInterestWork || {}
                    if (willInterestWork) {
                        evalResult.willInterestWork = willInterestWork.indexOf('/') != -1 ? willInterestWork.split('/') : [willInterestWork]
                    }
                    this.$nextTick(() => {
                        this.$refs.type9PersonalityTest.initInfo(evalResult)
                    })
                    break
                case '3':
                    // 需要处理一下 树格式 再传给 组件
                    if (evalResult && evalResult instanceof Array) {
                      evalResult.map(item => {
                        if (item.characterTrait) {
                          let currentData = item.characterTrait.indexOf('&') !== -1 ? item.characterTrait.split('&') : ['暂无', '暂无']
                          item.lowScoreDesc = currentData[1]
                          item.highScoreDesc = currentData[0]
                        }
                      })
                      this.$nextTick(() => {
                        this.$refs.carteTest.initInfo(evalResult)
                      })
                    }else {
                      console.log("%c[卡特尔16人格]测评结果格式不正确，期望类型：Array, 查询结果↑： detail.evalResult.result", "color:red");
                      this.detail.evalResult = null;
                    }
                    break
            }
        },
    }
}
</script>
<style lang='scss' scoped>
.result-card-wrap {
    height: 100%;
    position: relative;
    .interviewResult {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .interView-desc-box {
        flex: 1;
        background: #FFFFFF;
        margin-top: 14px;
        padding: 21px 30px 24px 30px;
        border-radius: 12px;

        .desc-box--title {
            font-weight: bold;
            font-size: 18px;
            color: #333333;
            display: flex;
            margin-bottom: 10px;
            align-items: center;

            .desc-box--divide {
                width: 3px;
                height: 15px;
                background: #3076FF;
                margin-right: 8px;
            }
        }
        .desc-box--content {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            margin-left: 5px;
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            .score {
                min-width: 100px;
                margin-right: 36px;
                font-weight: 600;
                font-size: 55px;
                color: #3076FF;
                // line-height: 77px;
            }
        }
    }
}
</style>
