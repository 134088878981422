import { render, staticRenderFns } from "./jobDetailsAI.vue?vue&type=template&id=7d4ccd36&scoped=true"
import script from "./jobDetailsAI.vue?vue&type=script&lang=js"
export * from "./jobDetailsAI.vue?vue&type=script&lang=js"
import style1 from "./jobDetailsAI.vue?vue&type=style&index=1&id=7d4ccd36&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d4ccd36",
  null
  
)

export default component.exports