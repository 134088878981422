import { render, staticRenderFns } from "./resultTable.vue?vue&type=template&id=4b27d460&scoped=true"
import script from "./resultTable.vue?vue&type=script&lang=js"
export * from "./resultTable.vue?vue&type=script&lang=js"
import style0 from "./resultTable.vue?vue&type=style&index=0&id=4b27d460&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b27d460",
  null
  
)

export default component.exports