
// 导入所需的库和模块
import axios from 'axios';
import store from "@/store";
import { Message } from "element-ui"
import {decrypt, encrypt, generateSignature} from '@/utils/cryptoUtil/cryptoUtils';

const axiosInstance = axios.create({
    // baseURL: 'http://1.95.87.172:80', // 测试 设置您的API基础URL
    // baseURL: 'http://222.85.202.67:688', // 测试 设置您的API基础URL
    // baseURL: 'https://zggjx.com.cn', // 设置正式 您的API基础URL
    timeout: 60000,
});

// 添加请求拦截器，用于处理请求数据加密
axiosInstance.interceptors.request.use(
    (config) => {

        const token = !store.state.user.token ? sessionStorage.getItem('token') : store.state.user.token;
        config.headers['AppId'] = process.env.VUE_APP_APP_ID;
        config.headers['version'] = '1.0';
        if (token) {
            config.headers['Authorization'] = token;
        }
        const timestamp = new Date().getTime().toString();
        const sign = generateSignature(timestamp);
        const { data, shouldEncrypt } = config; // 提取data和shouldEncrypt参数
        if(/get/i.test(config.method)){
            config.params = config.params || {};
            config.params.sign = sign;
            config.params.timestamp = timestamp;
        }else {
            config.data = {
                timestamp: timestamp,
                sign: sign,
                data: shouldEncrypt ? encrypt({frontData:data}) : data,
            };
        }
        return config;
    },
    (error) => Promise.reject(error),
);

// 添加响应拦截器，用于处理返回数据解密
axiosInstance.interceptors.response.use(
    (response) => {
        const { shouldDecrypt } = response.config; // 提取shouldDecrypt参数
        if(response.data.code === 200){
            if (shouldDecrypt && response.data.data !== undefined) {
                response.data.data = JSON.parse(decrypt(response.data.data)); // 对返回数据进行解密
                return response.data;
            }else {
                return response.data ;
            }
        }else{
            if(response.data.code === 401 || response.data.code === 403){
                Message.closeAll()
                Message({
                    message: '请登录！',
                    type: 'warning',
                    customClass: 'zZindex',
                });
                sessionStorage.removeItem('token');
                store.commit('SET_TOKEN', null)
                store.commit('SET_LOGINTIMES',store.state.user.loginTimes + 1)
            }
            if(response.data.code == 500) {
                let msg = response.data.message || response.data.msg
                if(msg) {
                    Message({
                        message: msg,
                        type: 'error',
                        customClass: 'zZindex',
                    });
                }
            }
            // this.$message.error(response.data.msg)
            return Promise.reject(response.data);
        }
    },
    (error) => Promise.reject(error),
);

export default axiosInstance;
